import { invoiceTypes } from "../constants/invoice-types.constants";
import { invoiceVatPercent } from "../constants/invoice-vat.constants";
import { addNumber, subNumber } from "./decimal.helper";

const currencyHelper = {
  // use for format amount on table
  format(money) {
    if (money) {
      let result = money;
      if (typeof money === 'string') result = parseFloat(result);
      return result
        .toFixed(2)
        .replace('.', ',')
        .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$& ');
    }
    return null;
  },
  formatMoney(amount, decimalCount = 2, thousands = ' ') {
    /*
     FORCUS ON NOWARY MONEY:
     + 1.123,23
     + 1.123.456,23
     + -1 233,23
     + -1 233 788,23
    */
    // 1 - replace space with no space
    amount = amount
      .toString()
      .replace(/ /g, '');
    // invalid amount => dont format and return amount
    const regex1 = /^-?((?:\d+|\d{1,3}(?:.\d{3})+)(?:,\d+)?)$/;
    const regex2 = /^-?((?:\d+|\d{1,3}(?:,\d{3})+)(?:.\d+)?)$/;

    if (regex1.test(amount) === false && regex2.test(amount) === false) return amount;
    const periods = (amount.match(/\./g) || []).length;
    const commas = (amount.match(/,/g) || []).length;

    /*
      case 1: 1 period and no coma keep and but 1 coma replace with period
      123.22
      -123.22
      123,22
      -123,22
    */

    if (periods === 0 && commas === 1) {
      amount = amount.replace(/\,/, '.');
    }
    /* CASE 2: 1 period and 1 coma
     1,233.22
     -1,233.22
     1.233,22
     -1.233,22
    */
    if (periods === 1 && commas === 1) {
      // get coma pos and poriod pos
      const comaPos = amount.indexOf(',');
      const periodPos = amount.indexOf('.');
      // console.log(50, { comaPos, periodPos });
      if (periodPos > comaPos) {
        // period last
        amount = amount.replace(/,/g, '');
      } else {
        // coma last
        amount = amount
          .replace(/\./, '')
          .replace(/\,/, '.');
      }
    }

    /* CASE 3: multiple period and 1 coma
    1.123.456,23
    -1.123.456,23
    */
    if (periods > 1 && commas === 1) {
      amount = amount
        .replace(/\./g, '')
        .replace(/,/, '.');
    }

    /* CASE 3: multiple coma and 1 period
      1,123,456.23
      -1,123,456.23
    */
    if (commas > 1 && periods === 1) {
      amount = amount.replace(/,/g, '');
    }
    /* CASE 4: multiple coma only
      1,123,456,233
      -1,123,456,233
    */
    if (commas > 1 && periods === 0) {
      amount = amount.replace(/,/g, '');
    }
    /* CASE 5: multiple period only
      1.123.456.233
      -1.123.456.233
    */
    if (commas === 0 && periods > 1) {
      amount = amount.replace(/\./g, '');
    }
    decimalCount = Math.abs(decimalCount);
    decimalCount = isNaN(decimalCount) ? 2 : decimalCount;
    const negativeSign = amount < 0 ? '-' : '';
    const i = parseFloat(amount = currencyHelper.roundToFixed(Math.abs(Number(amount) || 0), decimalCount)).toString().replace('.', ',');
    const f = amount.split('.');
    const num = i.split(',')[0];
    const j = (num.length > 3) ? num.length % 3 : 0;
    const t = negativeSign
      + (j ? num.substr(0, j) + thousands : '')
      + num.substr(j).replace(/(\d{3})(?=\d)/g, '$1' + thousands);

    if (decimalCount === 0) {
      return t;
    }

    const subFloat = f[1] === '00' ? ',00' : (f[1].length < decimalCount ? `,${f[1]}0` : `,${f[1]}`);
    return `${t}${subFloat}`;
  },
  clearFormatMoney(x) {
    if (x) {
      return x.toString()
        .replace(/\s+/g, '')
        .replace(',', '.');
    }
    return '';
  },
  clearFormatMoneyToSaveDB(x) {
    if (x) {
      return x.toString()
        .replace(/\s+/g, '')
        .replace(',', '.');
    }
    return '';
  },
  // redux-form and amount is string
  formatAmount(amount) {
    if (!amount) return '';
    // Convert to currency format
    const formated = amount
      .toString()
      .replace(/[a-z]|[A-Z]/g, '')
      .replace(/ /g, '')
      .replace(/\./g, ',')
      .replace(/,(?=.*,)/g, '')
      .replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
    if (formated.indexOf(',') !== -1) {
      const str = formated.split(',');
      const first = str[0];
      let last = str[1].replace(/ /g, '');
      last = last.length >= 2 ? last.substr(0, 2) : last;
      return `${first},${last}`;
    }
    return formated;
  },
  nomalizeAmount(amount) {
    if (!amount) return '';
    // Convert to currency format
    return amount
      .replace(/ /g, '')
      .replace(/,/g, '.');
  },
  handleChange: value => {
    value = value.replace(/[a-zA-Z]/g, '');
    return value;
  },
  handleChangePositiveAmount: value => {
    value = value.replace(/[^0-9\.,]/g, '');
    return value;
  },
  strToFloat: value => {
    return value && parseFloat(value.replace(',', '.'));
  },
  processAmount: invoice => {
    const amount = invoice?.amount ?? 0
    const amountCreditNote = invoice?.amount_credit_note ?? 0
    const amountPayment = invoice?.amount_payment ?? 0
    const paymentFee = invoice?.payment_fee ?? 0;

    const totalAmount = addNumber(amount, amountCreditNote);

    let outstanding = subNumber(addNumber(amount, amountCreditNote), amountPayment);
    if (Number(invoice?.status) === 6)
      outstanding = 0

    return {
      amount,
      amountCreditNote,
      amountPayment,
      paymentFee,
      outstanding,
      totalAmount,
    }
  },
  convertFloat(val) {
    let newVal = parseFloat(val);
    if (typeof val === 'string') {
      newVal = parseFloat(val.replace(/\s/g, '').replace(',', '.'));
    }
    if (!isNaN(newVal))
      return newVal;
    return 0;
  },
  roundToFixed: (number, decimalPlaces) => {
    const multiplier = Math.pow(10, decimalPlaces);
    const roundedNumber = Math.round(number * multiplier) / multiplier;
    return roundedNumber.toFixed(decimalPlaces);
  },
  formatStandard: value => {
    return currencyHelper.clearFormatMoney(currencyHelper.formatMoney(value));
  },
  amountCalculate: (invoiceProducts, params) => {
    let amount_original = 0;
    let amount_rounding = 0;
    let amount_vat = 0;
    let amount_discount = 0;
    let amount_excl_vat = 0;
    let amount_incl_vat = 0;
    let amount_excl_discount = 0;
    let amount_incl_discount = 0;
    let amount = 0;
    let nets = 0;
    let discounts = 0;
    let totals = 0;

    let negative = params?.type === invoiceTypes.CreditNoteInvoice ? -1 : 1;

    invoiceProducts.forEach((product) => {
      console.log("product", product);
      const { price, qty, discount, vat } = product;

      let priceRow = currencyHelper.convertFloat(price);
      let qtyRow = currencyHelper.convertFloat(qty);
      let discountRow = 0;
      if (params?.discount) {
        discountRow = currencyHelper.convertFloat(discount)
      }
      let vatRow = 0;
      if (params?.check_vat === true && vat > 0) {
        vatRow = invoiceVatPercent[vat];
      }

      let amountRow = priceRow * qtyRow;
      let amountDiscountRow = amountRow * discountRow / 100;
      let amountVatRow = (amountRow - amountDiscountRow) * vatRow / 100;

      let amountExclVatRow = amountRow - amountDiscountRow;
      let amountInclVatRow = amountRow - amountDiscountRow + amountVatRow;
      let amountExclDiscountRow = amountRow + (amountRow * vatRow / 100);
      let amountInclDiscountRow = amountRow - amountDiscountRow + amountVatRow;

      amount_original += amountRow;
      amount_discount += amountDiscountRow;
      amount_vat += amountVatRow;
      amount_excl_vat += amountExclVatRow;
      amount_incl_vat += amountInclVatRow;
      amount_excl_discount += amountExclDiscountRow;
      amount_incl_discount += amountInclDiscountRow;
      amount += amountRow - amountDiscountRow + amountVatRow;
      nets += (amountRow - amountDiscountRow);
      discounts += amountDiscountRow;
      totals += (amountRow - amountDiscountRow + amountVatRow);
    });

    if (params?.rounding) {
      amount_rounding = (currencyHelper.round(currencyHelper.convertFloat(amount)) - currencyHelper.convertFloat(amount))
      amount = currencyHelper.round(currencyHelper.convertFloat(amount));
    }

    return {
      amount_original: currencyHelper.formatStandard(amount_original * negative),
      amount_rounding: currencyHelper.formatStandard(amount_rounding * negative),
      amount_vat: currencyHelper.formatStandard(amount_vat * negative),
      amount_discount: currencyHelper.formatStandard(amount_discount * negative),
      amount_excl_vat: currencyHelper.formatStandard((amount_excl_vat + amount_rounding) * negative),
      amount_incl_vat: currencyHelper.formatStandard((amount_incl_vat + amount_rounding) * negative),
      amount_excl_discount: currencyHelper.formatStandard((amount_excl_discount + amount_rounding) * negative),
      amount_incl_discount: currencyHelper.formatStandard((amount_incl_discount + amount_rounding) * negative),
      amount: currencyHelper.formatStandard(amount * negative),
      nets: currencyHelper.formatStandard(nets * negative),
      discounts: currencyHelper.formatStandard(discounts * negative),
      totals: currencyHelper.formatStandard(totals * negative),
    };
  },
  round: (number) => {
    return Math.sign(number) * Math.round(Math.abs(number))
  },
};

export default currencyHelper;