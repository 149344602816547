import ServerConfig from '../configs/server.configs';

const endPointAccount = ServerConfig[process.env.NODE_ENV].ACCOUNT_API + '/api';
const endPointOryton = ServerConfig[process.env.NODE_ENV].orytonEndPoint;
const endPointBilagsky = ServerConfig[process.env.NODE_ENV].bilagskyEndPoint + '/api';
const endPointInvoices = ServerConfig[process.env.NODE_ENV].server;
const endPointAccountant = ServerConfig[process.env.NODE_ENV].accountantEndPoint;
const endPointMailServer = ServerConfig[process.env.NODE_ENV].mailServerEndPoint;
const endPointContactsDb = ServerConfig[process.env.NODE_ENV].contactsDbEndPoint;
const bucketXml = ServerConfig[process.env.NODE_ENV].bucketXml;

export const api = {
  account: {
    checkLogin: `${endPointAccount}/check-login`,
    getUserByToken: `${endPointAccount}/users/token/:token`,
    changeLanguage: `${endPointAccount}/language`
  },
  // ORYTON SERVER
  oryton: {
    // getListCompany: `${endPointOryton}/api/subscriptions/products/1`,
    getListCompany: `${endPointOryton}/api/companies?nopage=1&filterStatusArr=1,2`,
    // getListCompany: `${endPointBilagsky}/company/get-oryton-companies`,
    addCompany: `${endPointOryton}/api/companies`,
    // getListCompanyAutocomplete: `${endPointOryton}/api/3rd/brreg/list/:keyword`,
    getListCompanyAutocomplete: `${endPointContactsDb}/brreg?keyword=:keyword&ob=1`,
    getCurrentCompany: `${endPointOryton}/ajax/companies/:companies_id`,
    listUsers: `${endPointOryton}/ajax/users/list`,
  },
  bankAccount: {
    get: `${endPointBilagsky}/bank-accounts/:id`,
    list: `${endPointBilagsky}/company-banks/get-setting-bank-accounts`,
    create: `${endPointBilagsky}/banks`,
    update: `${endPointBilagsky}/banks`,
    updateStatus: `${endPointBilagsky}/banks/updateStatus`,
    delete: `${endPointBilagsky}/company-banks/:id`,
    getBankBySwiftCode: `${endPointBilagsky}/banks/bank/bySwiftCode`,
    defaultBank: `${endPointBilagsky}/company-banks/set-default-account-number`,
    getBankByCompanyId: `${endPointBilagsky}/banks/getByCompany`,
  },
  companiesServices: {
    get: `${endPointInvoices}/api/companies-services/:companies_id`,
    payment: `${endPointInvoices}/api/companies-services/payment/:companies_id`,
    addPayment: `${endPointInvoices}/api/companies-services/payment/:companies_id`,
    updatePayment: `${endPointInvoices}/api/companies-services/payment/:companies_id/:id`,
    companyEHF: `${endPointBilagsky}/company/services/ehf`,
    projectService: `${endPointInvoices}/api/companies-services/project/:companies_id`,
    debtCollection: `${endPointInvoices}/api/companies-services/debt-collection/:companies_id`,
    getKidOcr: `${endPointInvoices}/api/companies-services/kid-ocr/:companies_id`,
    getKidOcrs: `${endPointInvoices}/api/companies-services/kid-ocrs/:companies_id`,
    registerOcr: `${endPointInvoices}/api/companies-services/register-ocr/:companies_id`,
    getOcrStatus: `${endPointInvoices}/api/companies-services/get-ocr-status/:companies_id`,
    cancelOcr: `${endPointInvoices}/api/companies-services/cancel-ocr/:companies_id/:bank_account_id`,
    registerOcrExists: `${endPointInvoices}/api/companies-services/register-ocr-exists/:companies_id`,
    kraviaServices: `${endPointInvoices}/api/companies-services/kravia/:companies_id`,
    getKraviaServices: `${endPointInvoices}/api/companies-services/kravia/:companies_id`,
    visaCardServices: `${endPointInvoices}/api/companies-services/visa-card/:companies_id`,
    sendForCollection: `${endPointInvoices}/api/companies-services/send-for-collection/:companies_id`,
    services: `${endPointInvoices}/api/companies-services/services`,
  },
  options: {
    get: `${endPointInvoices}/api/options/`
  },
  dataBrreg: {
    get: 'https://data.brreg.no/enhetsregisteret/api/enheter/',
    // get: 'https://api.contacts.oryton.no/company/'
  },
  elma: {
    get: `${endPointBilagsky}/elma/participants/:organization_number`,
    checkEhf: `${endPointInvoices}/api/ehf/:organization_number`,
  },
  customer: {
    list: `${endPointInvoices}/api/customer`,
    get: `${endPointInvoices}/api/customer/:companies_id/:id`,
    add: `${endPointInvoices}/api/customer`,
    update: `${endPointInvoices}/api/customer/:id`,
    delete: `${endPointInvoices}/api/customer/:companies_id/:id`,
  },
  customerOrders: {
    list: `${endPointInvoices}/api/customer-orders`,
  },
  customerInvoices: {
    list: `${endPointInvoices}/api/customer-invoices`,
  },
  customerDiscounts: {
    list: `${endPointInvoices}/api/customer-discounts`,
    save: `${endPointInvoices}/api/customer-discounts`,
    delete: `${endPointInvoices}/api/customer-discounts/:companies_id/:customer_id/:product_id`,
  },
  customerNotes: {
    list: `${endPointInvoices}/api/customer-notes`,
    save: `${endPointInvoices}/api/customer-notes`,
    delete: `${endPointInvoices}/api/customer-notes/:customer_id/:id`,
  },
  products: {
    list: `${endPointInvoices}/api/products/list`,
    get: `${endPointInvoices}/api/products/get`,
    add: `${endPointInvoices}/api/products/add`,
    update: `${endPointInvoices}/api/products/update/:id`,
    delete: `${endPointInvoices}/api/products/delete/:id`,
    listSales: `${endPointInvoices}/api/products/list-sales`,
    getLastProductNo: `${endPointInvoices}/api/products/get-last-product-no`,
    listDiscounts: `${endPointInvoices}/api/products/list-discounts`,
  },
  invoices: {
    list: `${endPointInvoices}/api/invoices`,
    item: `${endPointInvoices}/api/invoices/:companies_id/:id`,
    create: `${endPointInvoices}/api/v2/invoices`,
    update: `${endPointInvoices}/api/invoices/:id`,
    delete: `${endPointInvoices}/api/invoices/:id`,
    preview: `${endPointInvoices}/api/v2/invoices`,
    saveNote: `${endPointInvoices}/api/invoices/save-note`,
    sendInvoice: `${endPointInvoices}/api/invoices/send`,
    lastInvoiceNo: `${endPointInvoices}/api/invoices/last-invoice-no`,
    sendEhf: `${endPointInvoices}/api/ehf`,
    confirmInvoice: `${endPointInvoices}/api/invoices/confirm`,
    getInvoiceMatching: `${endPointInvoices}/api/invoices/get-invoice-matching`,
    getXmlDocument: `${endPointInvoices}/api/file?file=${bucketXml}/:xml_path`,
    accountingDetail: `${endPointInvoices}/api/invoices/accounting-detail`,
    reCreateInvoice: `${endPointInvoices}/api/invoices/re-create-invoice`,
    invoiceDocument: `${endPointInvoices}/api/invoices/invoice-document`,
    uploadInvoice: `${endPointInvoices}/api/invoices/upload-invoice`,
    paymentCredit: `${endPointInvoices}/api/invoices/invoice-payment-credit`,
    registerPaymentCredit: `${endPointInvoices}/api/invoices/register-payment-credit`,
  },
  invoiceNote: {
    list: `${endPointInvoices}/api/invoice-note`,
    delete: `${endPointInvoices}/api/invoice-note/:id`,
    saveNote: `${endPointInvoices}/api/invoice-note`,
    previewFile: `${endPointInvoices}/api/invoice-note/preview-file`,
  },
  invoiceHistory: {
    list: `${endPointInvoices}/api/invoice-history`,
  },
  registerPayment: {
    save: `${endPointInvoices}/api/register-payment`,
    delete: `${endPointInvoices}/api/register-payment/:id`,
  },
  loseInvoice: {
    save: `${endPointInvoices}/api/lose-invoice`,
    delete: `${endPointInvoices}/api/lose-invoice/:id`,
  },
  reminderInvoice: {
    list: `${endPointInvoices}/api/reminder-invoice`,
    save: `${endPointInvoices}/api/reminder-invoice`,
  },
  invoiceSettings: {
    list: `${endPointInvoices}/api/invoice-settings`,
    save: `${endPointInvoices}/api/invoice-settings`,
    item: `${endPointInvoices}/api/invoice-settings/:companies_id`,
    preview: `${endPointInvoices}/api/invoice-settings/:companies_id/preview`,
  },
  report: {
    invoiceReport: `${endPointInvoices}/api/report/invoice`,
    productSales: `${endPointInvoices}/api/report/product-sales`,
    customerInvoice: `${endPointInvoices}/api/report/customer-invoice`,
    discountReport: `${endPointInvoices}/api/report/discount`,
    accountReceivableReport: `${endPointInvoices}/api/report/account-receivable`,
    export: `${endPointInvoices}/api/report/export`,
  },
  files: {
    view: `${endPointInvoices}/api/file?file=`,
    download: `${endPointInvoices}/api/file/download?file=:file`,
    logo: `${endPointInvoices}/api/file/logo?file=:file`,
  },
  InvoiceBroadcasts: {
    list: `${endPointInvoices}/api/invoice-broadcasts`,
  },
  repeatingInvoices: {
    list: `${endPointInvoices}/api/repeating-invoices`,
    preview: `${endPointInvoices}/api/repeating-invoices/preview/:id`,
    get: `${endPointInvoices}/api/repeating-invoices/:id`,
    delete: `${endPointInvoices}/api/repeating-invoices/:id`,
    save: `${endPointInvoices}/api/repeating-invoices`,
    update: `${endPointInvoices}/api/repeating-invoices/:id`,
    updateStatus: `${endPointInvoices}/api/repeating-invoices/status/:id`,
    chartData: `${endPointInvoices}/api/repeating-invoices/chart-data`,
  },
  service: {
    ehf: `${endPointBilagsky}/company/services/ehf`,
    deleteEhf: `${endPointBilagsky}/company/services/ehf/delete`,
    alert: `${endPointBilagsky}/company/services/alert`,
    getOwner: `${endPointBilagsky}/company/getOwner`,
    addCompanyUsers: `${endPointBilagsky}/company/addCompanyUsers`,
  },
  creditChecking: {
    get: 'https://data.brreg.no/enhetsregisteret/api/enheter/',
    // get: 'https://api.contacts.oryton.no/company/',
    list: `${endPointInvoices}/api/credit-check`,
  },
  company: {
    save: `${endPointInvoices}/api/company`,
    uploadLogo: `${endPointInvoices}/api/company/upload-logo`,
    removeLogo: `${endPointInvoices}/api/company/remove-logo`,
    get: `${endPointInvoices}/api/company/:id`,
    getCounter: `${endPointInvoices}/api/company/counter/:id`,
    getTopCustomer: `${endPointInvoices}/api/company/get-top-customer/:id`,
    getTopDecayed: `${endPointInvoices}/api/company/get-top-decayed/:id`,
    getUnpaidOnSales: `${endPointInvoices}/api/company/get-unpaid-on-sales/:id`,
    getOwner: `${endPointInvoices}/api/company/owner/:id`,
    list: `${endPointInvoices}/api/company`,
    accountingCompany: `${endPointInvoices}/api/company/accounting-company`,
    listAll: `${endPointInvoices}/api/company/list/all`,
  },
  invoicesDraft: {
    list: `${endPointInvoices}/api/invoices-draft`,
    item: `${endPointInvoices}/api/invoices-draft/:companies_id/:id`,
    create: `${endPointInvoices}/api/v2/invoice-drafts`,
    update: `${endPointInvoices}/api/v2/invoice-drafts/:id`,
    delete: `${endPointInvoices}/api/invoices-draft/:id`,
  },
  invoiceTypeSettings: {
    list: `${endPointInvoices}/api/invoice-type-settings`,
    save: `${endPointInvoices}/api/invoice-type-settings`,
    item: `${endPointInvoices}/api/invoice-type-settings/:companies_id`,
  },
  projects: {
    list: `${endPointInvoices}/api/projects`,
    get: `${endPointInvoices}/api/projects/:id`,
    add: `${endPointInvoices}/api/projects`,
    update: `${endPointInvoices}/api/projects/:id`,
    delete: `${endPointInvoices}/api/projects/:id`,
    getLastProjectNo: `${endPointInvoices}/api/projects/last-project-no`,
  },
  invoiceConfirm: {
    get: `${endPointInvoices}/api/invoice-confirm/:companies_id/:id/:confirm_token`,
    confirm: `${endPointInvoices}/api/invoice-confirm/:companies_id/:id/:confirm_token`,
  },
  companiesUsers: {
    list: `${endPointInvoices}/api/companies-users`,
    getCompaniesUsers: `${endPointInvoices}/ajaxServices/fetchCompanyUsers`,
    add: `${endPointInvoices}/api/companies-users`,
    delete: `${endPointInvoices}/api/companies-users/:id`,

    get: `${endPointInvoices}/api/companies-users/:id`,
    update: `${endPointInvoices}/api/companies-users/:id`,
    listUsers: `${endPointInvoices}/api/companies-users/users/list`,
  },
  emailForwards: {
    list: `${endPointMailServer}/api/mail-bilagsky/forward`,
    add: `${endPointMailServer}/api/mail-bilagsky/forward`,
    delete: `${endPointMailServer}/api/mail-bilagsky/forward/:uuid`,
  },
  queue: {
    invoiceForward: `${endPointInvoices}/api/queue/invoice-forward`,
  },
  userPermission: {
    get: `${endPointInvoices}/api/users-permission/:companies_id/:users_id`,
    add: `${endPointInvoices}/api/users-permission`,
  },
  customerContact: {
    list: `${endPointInvoices}/api/customer-contact/:companies_id/:customer_id`,
    get: `${endPointInvoices}/api/customer-contact/:companies_id/:customer_id/:id`,
    delete: `${endPointInvoices}/api/customer-contact/:companies_id/:customer_id/:id`,
    save: `${endPointInvoices}/api/customer-contact/:companies_id/:customer_id`,
    options: `${endPointInvoices}/api/customer-contact/:companies_id/:customer_id/options`,
    contactOptions: `${endPointInvoices}/api/customer-contact/:companies_id/:customer_id/contact-options`,
  },
  reminderSettings: {
    save: `${endPointInvoices}/api/:companies_id/reminder-settings`,
    get: `${endPointInvoices}/api/:companies_id/reminder-settings/:companies_id`,
    delete: `${endPointInvoices}/api/:companies_id/reminder-settings/:companies_id`,
  },
  invoiceDistribution: {
    list: `${endPointInvoices}/api/invoice-distribution`,
    delete: `${endPointInvoices}/api/invoice-distribution/:companies_id/:id`,
    save: `${endPointInvoices}/api/invoice-distribution`,
    sends: `${endPointInvoices}/api/invoice-distribution/sends`,
  },
  invoiceDistributionRun: {
    list: `${endPointInvoices}/api/invoice-distribution-run`,
    delete: `${endPointInvoices}/api/invoice-distribution-run/:id`,
    save: `${endPointInvoices}/api/invoice-distribution-run`,
    getItem: `${endPointInvoices}/api/invoice-distribution-run/:companies_id/:distribution_id`,
    method: `${endPointInvoices}/api/invoice-distribution-run/method`,
  },
  invoiceDistributionReceivers: {
    list: `${endPointInvoices}/api/invoice-distribution-receivers`,
    delete: `${endPointInvoices}/api/invoice-distribution-receivers/:companies_id/:distribution_id/:id`,
    save: `${endPointInvoices}/api/invoice-distribution-receivers`,
  },
  salesMans: {
    list: `${endPointInvoices}/api/sales-mans`,
    get: `${endPointInvoices}/api/sales-mans/:id`,
    create: `${endPointInvoices}/api/sales-mans`,
    update: `${endPointInvoices}/api/sales-mans/:id`,
    delete: `${endPointInvoices}/api/sales-mans/:id`,
    deleteByUsersId: `${endPointInvoices}/api/sales-mans/:companies_id/:users_id`,
  },
  getCompaniesPaymentPackages: {
    buy: `${endPointInvoices}/api/companies-payment-packages`,
    creditCheck: `${endPointInvoices}/api/companies-payment-packages/credit-check`,
    get: `${endPointInvoices}/api/companies-payment-packages/:companies_id`,
    update: `${endPointInvoices}/api/companies-payment-packages`,
  },
  payments: {
    buy: `${endPointInvoices}/api/payments/:uuid`,
    get: `${endPointInvoices}/api/payments/:uuid`,
  },
  ocrTransactions: {
    list: `${endPointInvoices}/api/ocr-transactions`,
    get: `${endPointInvoices}/api/ocr-transactions/:id`,
    listNotMatch: `${endPointInvoices}/api/ocr-transactions/not-match`,
    ignore: `${endPointInvoices}/api/ocr-transactions/ignore`,
  },
  invoiceMatching: {
    list: `${endPointInvoices}/api/invoice-matching`,
    get: `${endPointInvoices}/api/invoice-matching/:id`,
    ocr: `${endPointInvoices}/api/invoice-matching/ocr`,
    kravia: `${endPointInvoices}/api/invoice-matching/kravia`,
  },
  kraviaServices: {
    status: `${endPointInvoices}/api/kravia-services/status`,
  },
  accountants: {
    accountCodes: `${endPointAccountant}/account-codes/listAll/basic?max=all`,
  },
  kraviaTransactions: {
    list: `${endPointInvoices}/api/kravia-transactions`,
    listNotMatch: `${endPointInvoices}/api/kravia-transactions/not-match`,
    get: `${endPointInvoices}/api/kravia-transactions/:payment_id`,
  },
  kraviaInvocies: {
    list: `${endPointInvoices}/api/kravia-invoices`,
    get: `${endPointInvoices}/api/kravia-invoices/:id`,
    addExisted: `${endPointInvoices}/api/kravia-invoices/add-existed`,
  },
  kraviaInvocieNotes: {
    add: `${endPointInvoices}/api/kravia-invoice-notes`,
  },
  kraviaDocuments: {
    upload: `${endPointInvoices}/api/kravia-invoice-documents`,
    preview: `${endPointInvoices}/api/kravia-invoice-documents/preview`,
  },
  salesmanSettings: {
    list: `${endPointInvoices}/api/salesman-settings`,
    save: `${endPointInvoices}/api/salesman-settings`,
    item: `${endPointInvoices}/api/salesman-settings/:companies_id`,
  },
  creditCheck: {
    getCreditChecked: `${endPointInvoices}/api/credit-check/checked/:companies_id?id=:customer_id`,
    search: `${endPointInvoices}/api/credit-check/search`,
    download: `${endPointInvoices}/api/credit-check/download`,
  },
  vatSettings: {
    list: `${endPointInvoices}/api/vat-settings`,
    save: `${endPointInvoices}/api/vat-settings`,
    item: `${endPointInvoices}/api/vat-settings/:companies_id`,
  },
  vatCodeSettings: {
    list: `${endPointInvoices}/api/vat-code-settings`,
    save: `${endPointInvoices}/api/vat-code-settings`,
    item: `${endPointInvoices}/api/vat-code-settings/:companies_id`,
  },
  orders: {
    list: `${endPointInvoices}/api/orders`,
  },
  offers: {
    list: `${endPointInvoices}/api/offers`,
  },
  resetData: {
    save: `${endPointInvoices}/api/reset-data`,
    confirm: `${endPointInvoices}/api/reset-data/confirm`,
  },
  import: {
    upload: `${endPointInvoices}/api/import/upload`,
    invoice: `${endPointInvoices}/api/import/invoice`,
    customer: `${endPointInvoices}/api/import/customer`,
    product: `${endPointInvoices}/api/import/product`,
  },
  importLog: {
    list: `${endPointInvoices}/api/import-log`,
  },
  invoicePost: {
    send: `${endPointInvoices}/api/v2/invoice-post`,
  },
  adminReport: {
    invoiceReport: `${endPointInvoices}/api/admin-report/invoices`,
    creditChecked: `${endPointInvoices}/api/admin-report/credit-checked`,
    sentNoKid: `${endPointInvoices}/api/admin-report/sent-no-kid`,
    kidsReport: `${endPointInvoices}/api/admin-report/kids`,
    companyKidRegistered: `${endPointInvoices}/api/admin-report/kid-registered`,
    companiesInvoicesStatus: `${endPointInvoices}/api/admin-report/companies-invoices-status`,
    companiesInvoicesStatusList: `${endPointInvoices}/api/admin-report/companies-invoices-status/invoices`,
    sendPost: `${endPointInvoices}/api/admin-report/send-post`,
    invoiceSendPosts: `${endPointInvoices}/api/admin-report/invoice-send-posts`,
    invoiceServiceReport: `${endPointInvoices}/api/admin-report/invoice-services`,
    companyInvoiceServicesReport: `${endPointInvoices}/api/admin-report/company-invoice-services`,
    creditStatistics: `${endPointInvoices}/api/admin-report/credit-statistics`,
    creditStatisticsList: `${endPointInvoices}/api/admin-report/credit-statistics/:companies_id`,
  },
  subscriptionSettings: {
    list: `${endPointInvoices}/api/subscription-settings`,
    save: `${endPointInvoices}/api/subscription-settings`,
    item: `${endPointInvoices}/api/subscription-settings/:companies_id`,
  },
  customerProductInactivates: {
    list: `${endPointInvoices}/api/customer-product-inactivates`,
    save: `${endPointInvoices}/api/customer-product-inactivates`,
    delete: `${endPointInvoices}/api/customer-product-inactivates/:companies_id/:customer_id/:product_id`,
  },
  companiesSusPend: {
    invoices: `${endPointInvoices}/api/v2/companies-suspend/invoices`,
  },
  userInvoiceServices: {
    list: `${endPointInvoices}/api/v2/user-invoice-services`,
    add: `${endPointInvoices}/api/v2/user-invoice-services`,
    delete: `${endPointInvoices}/api/v2/user-invoice-services/:id`,

    get: `${endPointInvoices}/api/v2/user-invoice-services/:id`,
    update: `${endPointInvoices}/api/v2/user-invoice-services/:id`,
    listUsers: `${endPointInvoices}/api/v2/user-invoice-services/users`,
  },
  customerOverview: {
    list: `${endPointInvoices}/api/v2/customer-overview`,
  },
  eInvoice: {
    identifiers: `${endPointInvoices}/api/v2/e-invoices/identifiers`,
    send: `${endPointInvoices}/api/v2/e-invoices/send`,
    viewLog: `${endPointInvoices}/api/v2/e-invoices/view-log`,
  },
  avtalegiro: {
    send: `${endPointInvoices}/api/v2/avtalegiro/send`,
    check: `${endPointInvoices}/api/v2/avtalegiro/check`,
    avtalegiroStatistics: `${endPointInvoices}/api/v2/avtalegiro/statistics`,
    avtalegiroStatisticsList: `${endPointInvoices}/api/v2/avtalegiro/statistics-list`,
  },
  companiesInvoiceServicesExcept: {
    list: `${endPointInvoices}/api/v2/companies-invoice-services-except`,
    add: `${endPointInvoices}/api/v2/companies-invoice-services-except`,
    delete: `${endPointInvoices}/api/v2/companies-invoice-services-except/:id`,

    get: `${endPointInvoices}/api/v2/companies-invoice-services-except/:id`,
    update: `${endPointInvoices}/api/v2/companies-invoice-services-except/:id`,
  },
  kidOcr: {
    register: `${endPointInvoices}/api/v2/kid-ocr`,
    exist: `${endPointInvoices}/api/v2/kid-ocr/exist`,
  },
  mandate: {
    creditorCampaign: `${endPointInvoices}/api/v2/mandates/creditor-campaign`,
  },
  contracts: {
    list: `${endPointOryton}/api/company-franchise/list-contract-hyllemester`,
    file: `${endPointOryton}/api/file`,
    addPayment: `${endPointOryton}/api/company-franchise/:contract_id/add-payment`,
    deletePayment: `${endPointOryton}/api/company-franchise/:transaction_id/delete-payment`,
  },
  invoiceSubscriptions: {
    list: `${endPointInvoices}/api/v2/invoice-subscriptions`,
    save: `${endPointInvoices}/api/v2/invoice-subscriptions`,
  },
};
